import { enumKeys } from '../../utils/enum-utils';

export enum EnumRoleIntervenantDC {
  ETUDIEUR = 'ETUDIEUR',
  COMMERCIAL = 'COMMERCIAL',
  DECIDEUR = 'DECIDEUR',
  SIGNATAIRE_INTERNE = 'SIGNATAIRE_INTERNE',
  SUIVEUR_INTERNE = 'SUIVEUR_INTERNE',
  ASSISTANT = 'ASSISTANT',
  APPORTEUR_DC = 'APPORTEUR_DC',
}

export namespace EnumRoleIntervenantDC {
  // toString function
  export function toString(value: EnumRoleIntervenantDC): string {
    switch (value) {
      case EnumRoleIntervenantDC.ETUDIEUR:
        return 'Etudieur';
      case EnumRoleIntervenantDC.COMMERCIAL:
        return 'Commercial';
      case EnumRoleIntervenantDC.DECIDEUR:
        return 'Décideur';
      case EnumRoleIntervenantDC.SIGNATAIRE_INTERNE:
        return 'Signataire interne';
      case EnumRoleIntervenantDC.SUIVEUR_INTERNE:
        return 'Suiveur interne';
      case EnumRoleIntervenantDC.APPORTEUR_DC:
        return 'Apporteur DC';
      case EnumRoleIntervenantDC.ASSISTANT:
        return 'Assistant';
      default:
        return '';
    }
  }

  export function all(): EnumRoleIntervenantDC[] {
    return enumKeys(EnumRoleIntervenantDC) as unknown as EnumRoleIntervenantDC[];
  }
}
