export enum EnumTypeCritere {
  INDICATEUR_ELIGIBILITE = 'INDICATEUR_ELIGIBILITE',
  CRITERE_EVALUATION = 'CRITERE_EVALUATION',
  // TODO : remove enum values below
  DEPENSES_PROJETS = 'DEPENSES_PROJETS',
  REGIMES_AIDES = 'REGIMES_AIDES',
  DOCUMENT_AAP = 'DOCUMENT_AAP',
  DISPOSITIFS = 'DISPOSITIFS',
}

export namespace EnumTypeCritereNamespace {
  export function getTitle(enumValue: EnumTypeCritere): string {
    switch (enumValue) {
      case EnumTypeCritere.INDICATEUR_ELIGIBILITE:
        return "indicateurs d'éligibilité";
      case EnumTypeCritere.CRITERE_EVALUATION:
        return "critères d'évaluation";
      case EnumTypeCritere.DISPOSITIFS:
        return "stratégies d'accélération (dispositifs)";
      default:
        return '';
    }
  }
  export function getSubTitle(enumValue: EnumTypeCritere): string {
    switch (enumValue) {
      case EnumTypeCritere.INDICATEUR_ELIGIBILITE:
        return "un indicateur d'éligibilité";
      case EnumTypeCritere.CRITERE_EVALUATION:
        return "un critère d'évaluation";
      case EnumTypeCritere.DISPOSITIFS:
        return "des stratégies d'accélération (dispositifs)";
      default:
        return '';
    }
  }

  export function getButtonText(enumValue: EnumTypeCritere): string {
    switch (enumValue) {
      case EnumTypeCritere.INDICATEUR_ELIGIBILITE:
        return 'indicateur';
      case EnumTypeCritere.CRITERE_EVALUATION:
        return 'critère';
      case EnumTypeCritere.DISPOSITIFS:
        return 'dispositif';
      default:
        return '';
    }
  }
}
